#report-overview > div {
  text-align: center;
}

#report-overview > div > p {
  text-transform: uppercase;
}

#report-overview div.info > p {
  color: var(--color-info);
}

#report-overview div.danger > p {
  color: var(--color-danger);
}

#report-overview div.warning > p {
  color: var(--color-warning);
}

#report-overview div.success > p {
  color: var(--color-success);
}

#report-overview div.primary > p {
  color: var(--color-primary);
}

.tc-chart-js-legend {
  list-style-type: none;
  padding-left: 0;
}

.tc-chart-js-legend li {
  clear: both;
  display: block;
  float: left;
  padding: 10px;
}

.tc-chart-js-legend li span {
  display: block;
  float: left;
  height: 25px;
  margin-right: 10px;
  width: 25px;
}

/* The report builder */
.report-filter {
  border-color: var(--color-gray);
}

/* The report output */

.report-table th,
.report-table tr.grand-total > td {
  background-color: var(--color-primary);
}

.report-table > tbody > tr > th,
.report-table > thead > tr > th,
.report-table tr.grand-total > td {
  color: var(--color-white);
  font-weight: bold;
  padding: 15px 5px;
}

.report-table td.level-5 {
  background-color: var(--color-primary-300);
  color: var(--color-primary-800);
}

.report-table td.level-4 {
  background-color: var(--color-primary-400);
  color: var(--color-primary-800);
}

.report-table td.level-3 {
  background-color: var(--color-primary-500);
  color: var(--color-primary-900);
}

.report-table td.level-2 {
  background-color: var(--color-primary-600);
  color: var(--color-primary-100);
}

.report-table td.level-1 {
  background-color: var(--color-primary-700);
  color: var(--color-primary-200);
}

.report-table td.level-0 {
  background-color: var(--color-white);
  color: var(--color-gray);
}

.report-detailed .report-detailed-item {
  border: 2px solid var(--color-gray-400);
  margin: 8px 0 16px 0;
  padding: 0 10px;
}

.report-detailed .report-detailed-row {
  border-bottom: 1px solid var(--color-gray-200);
  padding: 6px 0;
}

.filter-list > .list-group > .list-group-item {
  overflow: visible;
}

.filter-list > .list-group > .list-group-item .ui-select-choices {
  /* This is a bit weird that we need to harcode it, but I couldn't find a way around it. */
  min-width: 400px;
}
